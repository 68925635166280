<template>
  <div class="flex">
    <div class="iconbox">
      <i class="el-icon-setting"></i>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .flex{
    display: flex;
  }
  .iconbox{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin-right: 5px;
    color: #409eff;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
    font-size: 20px;
  }
</style>
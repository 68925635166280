<template>
  <div class="body" v-loading="pageloading">
    <div class="flex" :style="{'margin-bottom':marbottom+'px'}">
      <div class="main">
        <div class="header">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/goodsdetails-title.png" style="width:375px" alt="">
        </div>
        <div class="basicmsg">
          <div class="subject">商品基本信息区</div>
          <div class="assistant">显示商品主图、卖点、规格、价格等基本信息，固定样式</div>
        </div>
        <div class="advertising" :class="{outline:num==1}" @click.stop="editmode(1)">
          <div class="cursor">
            <div class="internal-font" v-if="!form.config1||!form.config1.list||!form.config1.list.length">点击编辑广告位</div>
            <div v-else>
              <div v-if="form.config1.radioType==0">
                <img v-for="(v,i) in form.config1.list" :key="i" :src="imgurl+v.PageUrl" style="width:375px" alt="">
              </div>
              <div v-else>
                <el-carousel style="width:375px" :height="carouselheight1" class="pointer" arrow="never" trigger="click">
                  <el-carousel-item v-for="(v,i) in form.config1.list" :key="i">
                    <img :src="imgurl+v.PageUrl" style="width:375px" alt="" ref="carouselimg1" @load="imaload1">
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          
          <advertising1 v-show="num==1" class="first" key="first" name="图片广告位1" :data="form.config1"
            @draggableEnd="draggableEnd1" @changeimg="changeimg"></advertising1>
        </div>
        <div class="mall">
          <div class="logoname">
            <img :src="mallInfo.MallLogoUrlComplete" style="width:50px;height:50px;border-radius:50%" alt="">
            <span style="margin-left:12px">{{mallInfo.PcMallName}}</span>
          </div>
          <div class="logobutton" :style="{color:mallInfo.MainColor,border:`1px solid ${mallInfo.MainColor}`}">在线客服</div>
        </div>
        <div class="basicmsg">
          <div class="subject">推荐商品广告位</div>
          <div class="assistant">商品关联推荐，可自定义选择商品
            <el-link href="https://jusnn6k8al.feishu.cn/docx/M9OddUsEboMMWlxjwNwcb1PmnXb" type="primary"
            :underline="false" target="_blank" style="margin-left:10px">查看说明</el-link>
          </div>
        </div>
        <div class="evaluation">
          <div class="top">
            <div class="top-left">商品评价(888)</div>
            <div class="top-right">
              <span style="margin-right:5px" :style="{color:mallInfo.MainColor}">好评100%</span>
              <i class="el-icon-arrow-right" style="font-size:12px"></i>
            </div>
          </div>
          <div class="bottom">
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/pagemode-user-header.png" style="width:40px" alt="">
            <div class="content">
              <div class="msg">
                <div>
                  <div class="name">微信昵称</div>
                  <div class="name" style="color:#999999;">2021-06-13 20:57:16</div>
                </div>
                <div class="msg-righg">
                  <div class="name" style="color:#666666;">评价</div>
                  <el-rate
                    class="rate"
                    v-model="rate"
                    disabled
                    :colors="[mallInfo.MainColor,mallInfo.MainColor,mallInfo.MainColor]"
                    score-template="{rate}">
                  </el-rate>
                </div>
              </div>
              <div class="con-text">评价内容</div>
            </div>
          </div>
        </div>
        <div class="advertising" :class="{outline:num==2}" @click.stop="editmode(2)" style="margin-bottom:0px">
          <div class="cursor">
            <div class="internal-font" v-if="!form.config2||!form.config2.list||!form.config2.list.length">点击编辑广告位</div>
            <div v-else>
              <div v-if="form.config2.radioType==0">
                <img v-for="(v,i) in form.config2.list" :key="i" :src="imgurl+v.PageUrl" style="width:375px" alt="">
              </div>
              <div v-else>
                <el-carousel style="width:375px" :height="carouselheight2" class="pointer" arrow="never" trigger="click">
                  <el-carousel-item v-for="(v,i) in form.config2.list" :key="i">
                    <img :src="imgurl+v.PageUrl" style="width:375px;" alt="" ref="carouselimg2" @load="imaload2">
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <advertising1 v-show="num==2" class="second" key="second" name="图片广告位2" :data="form.config2"
            @draggableEnd="draggableEnd2" @changeimg="changeimg"></advertising1>
        </div>
        <div class="basicmsg" style="padding:79px 0px">
          <div class="subject">商品详情区</div>
          <div class="assistant">
            <el-button type="text" style="margin:0px;padding:0px" @click="toshoplist">前往商品列表</el-button>
            <span>，每个商品独立编辑</span>
          </div>
        </div>
        <div class="settlement">
          <div class="left">
            <div class="demobox">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/settlement-shouye.png" alt="">
              <span>首页</span>
            </div>
            <div class="demobox">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/settlement-kefu.png" alt="">
              <span>客服</span>
            </div>
            <div class="demobox">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/settlement-gouwuche.png" alt="">
              <span>购物车</span>
            </div>
          </div>
          <div class="right">
            <div class="light" :style="{background:mallInfo.MainColor+'1A',color:mallInfo.MainColor}">加入购物车</div>
            <div class="dark" :style="{background:mallInfo.MainColor}">立即购买</div>
          </div>
        </div>
        <div class="bottombar">
          <div class="blackbox">
            <div class="black"></div>
          </div>
        </div>
      </div>
      <div class="edit">

      </div>
    </div>

    <div class="bottom-banner">
      <div style="margin-left:8%"></div>
      <el-button type="primary" plain @click="save(1)">保存</el-button>
      <el-button type="primary" @click="save(2)">发布</el-button>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import advertising1 from './components/advertising1.vue'
import config from '@/config'
import {
  mallproductDetailAdvertisingSpaceConfig,
  mallproductDetailAdvertisingSpaceConfigSave
} from '@/api/sv3.0.0'
export default {
  computed: {
    ...mapGetters([
      'mallInfo'
    ]),
    marbottom(){
      return this.num==2?300:100
    }
  },
  components:{
    advertising1
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      imgapi:config.UPLOAD_IMG,
      pageloading:false,
      rate:5,
      num:0,
      form:{
        config1:{
          list:[],
          radioType:0,
        },
        config2:{
          list:[],
          radioType:0,
        },
      },
      carouselheight1:300+'px',
      carouselheight2:300+'px'
    }
  },
  mounted () {
    // console.log(this.mallInfo)
    this.getinfo()
    this.imaload1()
    this.imaload2()
    
  },
  methods: {
    changeimg(){
      if(this.num==1){
        this.imaload1()
      }else if(this.num==2){
        this.imaload2()
      }
    },
    imaload2(){
      this.$nextTick(()=>{
        if(!this.$refs.carouselimg2||!this.$refs.carouselimg2.length) return
        let heightlist = this.$refs.carouselimg2.map(v=>v.offsetHeight)
        this.carouselheight2 = Math.max(...heightlist)+'px'
      })
    },
    imaload1(){
      this.$nextTick(()=>{
        if(!this.$refs.carouselimg1||!this.$refs.carouselimg1.length) return
        let heightlist = this.$refs.carouselimg1.map(v=>v.offsetHeight)
        this.carouselheight1 = Math.max(...heightlist)+'px'
      })
    },
    async save(num){
      try{
        this.pageloading = true
        let res = await mallproductDetailAdvertisingSpaceConfigSave({
          IsRelease:num==2,
          ProductDetailAdvertisingSpace:JSON.stringify(this.form)
        })
        if(res.IsSuccess){
          this.$message.success(num==1?'保存成功':'发布成功')
          this.getinfo()
        }
      }finally{
        this.pageloading = false
      }
    },
    draggableEnd1(value){
      // console.log(value)
      this.form.config1 = value
    },
    draggableEnd2(value){
      this.form.config2 = value
      // console.log(value)
    },
    async getinfo(){
      try{
        this.pageloading = true
        let res = await mallproductDetailAdvertisingSpaceConfig()
        if(res.IsSuccess){
          let form = {
            config1:{
              list:[],
              radioType:0,
            },
            config2:{
              list:[],
              radioType:0,
            },
          }
          this.form = res.Result.ProductDetailAdvertisingSpace?JSON.parse(res.Result.ProductDetailAdvertisingSpace):form
          // console.log(this.form)
        }
        this.imaload1()
        this.imaload2()
      }finally{
        this.pageloading = false
      }
    },
    editmode(num){
      this.num = num
    },
    toshoplist(){
      this.$router.push({
        path:'/goods/goodsList'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .body{
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    // border:1px solid black;
    padding: 40px 100px 40px 0px;
  }
  .flex{
    display: flex;
  }
  .main{
    margin-left: 20%;
    width: 375px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
    margin-bottom: 100px;
    .header{
      width: 375px;
    }
    .basicmsg{
      width: 375px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0px;
      margin-bottom: 10px;
      .subject{
        font-size: 14px;
        color: #303133;
        line-height: 1.5;
      }
      .assistant{
        font-size: 14px;
        color: #999999;
        line-height: 1.5;
        display: flex;
        align-items: center;
      }
    }
    .advertising{
      width: 375px;
      background: #EBF8FD;
      color: #88C4DC;
      font-size: 14px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      position: relative;
      .cursor{
        cursor: pointer;
        img{
          vertical-align:bottom;
        }
        .internal-font{
          margin: 35px 0px;
        }
        .center{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .pointer{
          pointer-events: none;
          ::v-deep .el-carousel__button{
            border-radius: 50%;
            width: 7px;
            height: 7px;
          }
        }
      }
      .first{
        position: absolute;
        left: 400px;
        top: 0;
      }
      .second{
        position: absolute;
        left: 400px;
        top: 0;
      }
    }
    .mall{
      width: 375px;
      padding: 10px 20px 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      margin-bottom: 10px;
      .logoname{
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #000000;
        line-height: 1.5;
      }
      .logobutton{
        padding: 5px 15px;
        border-radius: 13px 13px 13px 13px;
        background: #FFFFFF;
        line-height: 1.5;
        font-size: 13px;
      }
    }
    .evaluation{
      width: 375px;
      background: white;
      margin-bottom: 10px;
      .top{
        border-bottom: 1px solid #F7F7F7;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 15px;
        .top-left{
          color: #000000;
          line-height: 1.5;
          font-size: 15px;
        }
        .top-right{
          line-height: 1.5;
          font-size: 15px;
        }
      }
      .bottom{
        display: flex;
        padding: 15px;
        align-items: flex-start;
        .content{
          flex:1;
          .msg{
            display: flex;
            justify-content: space-between;
            margin-left: 10px;
            .name{
              font-size: 13px;
              color: #000000;
              line-height: 1.5;
            }
            .msg-righg{
              display: flex;
              align-items: center;
              .rate{
                margin-left: 5px;
                ::v-deep .el-rate__icon{
                  margin:0px;
                }
              }
            }
          }
          .con-text{
            width: 100%;
            font-size: 13px;
            line-height: 1.5;
            color: #000000;
            margin: 8px 0px 0px 10px;
          }
        }
      }
    }
    .settlement{
      width: 375px;
      background: white;
      padding: 5px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex:4;
        margin-right: 20px;
        .demobox{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 10px;
          color: #666666;
          line-height: 1.5;
          img{
            width: 20px;
            height: 20px;
            margin-bottom: 2px;
          }
        }
      }
      .right{
        flex:6;
        display: flex;
        align-items: center;
        .light{
          border-radius: 23px 0px 0px 23px;
          font-size: 15px;
          line-height: 1.2;
          padding: 13px 17px;
        }
        .dark{
          border-radius: 0px 23px 23px 0px;
          font-size: 15px;
          line-height: 1.2;
          color: white;
          padding: 13px 17px;
        }
      }
    }
    .bottombar{
      width: 375px;
      background: white;
      position: relative;
      height: 34px;
      .blackbox{
        position: absolute;
        bottom: 9px;
        width: 100%;
        display: flex;
        justify-content: center;
        .black{
          border-radius: 100px 100px 100px 100px;
          background: #000000;width: 134px;
          height: 5px;
        }
      }
    }
    .outline{
      outline: 2px solid #409eff;
    }
  }
  
  .edit{

  }

  .bottom-banner{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    background: white;
  }
</style>
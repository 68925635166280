<template>
  <div>
    <setting>
      <el-card style="width:600px">
        <template slot="header">
          <div class="header-between">
            <div style="font-size:16px">{{name}}</div>
            <el-button type="text" style="margin:0px;padding:0px" @click="sampleShow=true">查看示例</el-button>
          </div>
        </template>
        <div class="flex" style="margin-bottom:10px">
          <div style="font-weight:700">添加图片</div>
          <div class="grayfont" style="margin-left:10px">最多添加5个广告，鼠标拖拽调整广告顺序</div>
        </div>
        <draggable class="imglist" v-model="values.list" v-bind="{animation: 100, handle:'.demobox'}" @end="draggableEnd">
          <div class="demobox" v-for="(v,i) in values.list" :key="i">
            <div class="graybox">
              <img :src="imgurl+v.PageUrl" style="" alt="">
              <div class="textbox">
                <el-dropdown trigger="click" placement="bottom-start" style="width:100%" @command="handleCommand($event,v,i)">
                  <div class="flex flex-align-center link-btn">
                    <div class="link-text">
                      <span style="color:#606266">链接：</span>
                      <span v-if="!v.PageName">请选择页面</span>
                      <span v-else>{{v.PageName}}</span>
                    </div>
                    <i class="el-icon-close" style="margin-left: 10px" @click="clearurl(i)" v-show='v.PageType'></i>
                    <i class="el-icon-arrow-down" style="margin-left: 5px"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">选择小程序内页面</el-dropdown-item>
                    <el-dropdown-item command="2">视频号直播预约/进入</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="">
              <div>
                <el-upload style="flex:1" ref="upload" class="uploadbox" :action="imgapi" :on-success="(e)=>{handleAvatarSuccess2(e,i)}" accept="image/*"
                 :show-file-list="false" :before-upload="beforeAvatarUpload" list-type="text">
                  <el-button type="text" style="margin:5px 0pxpx;padding:0px">修改</el-button>
                </el-upload>
              </div>
              <div>
                <el-button type="text" style="color:#f56c6c;margin:5px 0px;padding:0px" @click="detele(i)">删除</el-button>
              </div>
            </div>
          </div>
          <el-upload v-if="values.list.length<5" style="flex:1" ref="upload" class="uploadbox" :action="imgapi" :on-success="handleAvatarSuccess"
           accept="image/*" :show-file-list="false" :before-upload="beforeAvatarUpload" list-type="text">
            <div class="pushbox">
              <div class="top">
                <i class="el-icon-plus" style="font-size:14px;margin-right:10px"></i>
                <div>添加图片</div>
              </div>
              <div class="grayfont">建议图片宽750px，高180px；图片大小1M及以内</div>
            </div>
          </el-upload>
        </draggable>
        <div class="flex" style="margin-top:20px">
          <div style="font-weight:700">图片展示</div>
          <div class="flex" style="margin-left:10px">
            <el-radio-group v-model="values.radioType">
              <el-radio :label="0">一行一个</el-radio>
              <el-radio :label="1">轮播海报</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-card>
    </setting>
    <el-dialog :visible.sync="sampleShow" title="查看示例" class="dialog-body-paddingTop-10" width="600px">
      <div class="flex flex-justify-between">
        <div class="flex flex-column flex-align-center">
          <div style="font-size:14px;color:#606266">广告位1</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/advertising1-1.png" style="width:220px" alt="">
        </div>
        <div class="flex flex-column flex-align-center">
          <div style="font-size:14px;color:#606266">广告位2</div>
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/advertising1-2.png" style="width:220px" alt="">
        </div>
      </div>
    </el-dialog>
    <link-modal :showLiveTab="true"  :visible="visibleable"  @change="dataChange" @cancel="visibleable=false"></link-modal>
  </div>
</template>

<script>
import setting from './setting.vue'
import config from '@/config'
import linkModal from '@/views/components/linkModal';
import draggable from 'vuedraggable'
export default {
  components:{
    setting,
    linkModal,
    draggable
  },
  props:{
    name:{
      type:String,
      default:'图片广告位1'
    },
    data:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    data(val){
      this.values = val
    }
  },
  data () {
    return {
      imgurl:config.IMG_BASE,
      imgapi:config.UPLOAD_IMG,
      values:{
        list:[],
        radioType:0
      },
      editData:{},
      visibleable:false,
      currrentIndex:0,
      sampleShow:false,
    }
  },
  methods: {
    draggableEnd(list){
      this.$emit('draggableEnd',this.values)
    },
    detele(i){
      this.values.list.splice(i,1)
      this.$emit('changeimg')
    },
    handleCommand(e, record,i){
			this.editData = record; 
      this.currrentIndex = i
			if (e == '1'){
        this.visibleable = true;
        this.values.list[i].PageType = 1
			}else{
        this.values.list[i].PageType = 2
        this.values.list[i].PageName = '视频号直播预约/进入'
        this.values.list[i].PagePath = ''
			}
    },
    clearurl(i){
      this.values.list[i].PagePath = ''
      this.values.list[i].PageName = ''
      this.values.list[i].PageType = 0
    },
    handleAvatarSuccess2(file,i){
      this.values.list[i].PageUrl = file[0]
      this.$emit('changeimg')
    },
    handleAvatarSuccess(file){
      this.values.list.push({
        PageUrl:'',//图片
        PageName:'',//名称
        PagePath:'',//路径
        PageType:0//类型 1页面 2视频号直播预约/进入
      })
      this.values.list[this.values.list.length-1].PageUrl = file[0]
      this.$emit('changeimg')
    },
    beforeAvatarUpload(file){
      // console.log(file)
      let size = file.size/1024/1024 < 1
      let list = ['image/jpeg','image/jpg','image/png','image/gif']
      if(list.indexOf(file.type)==-1){
        this.$message.error('仅支持上传jpg，png，gif格式图片')
        return false
      }
      if (!size) {
        this.$message.error('广告位图片大小请控制在1M及以内');
        return false
      }
    },
    dataChange(record) {
      if (record.type == "classify") {
        this.values.list[this.currrentIndex].PageName = record.data.GroupName
        this.values.list[this.currrentIndex].MenuType = 1
        this.values.list[this.currrentIndex].PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
      } else if (record.type == "functional") {
        this.values.list[this.currrentIndex].PageName = record.data.name
        this.values.list[this.currrentIndex].MenuType = 2
        // 全部商品
        if (record.data.id == 1) {
          this.values.list[this.currrentIndex].PagePath = 'pages/index/index'
        } else if (record.data.id == 2) {
          this.values.list[this.currrentIndex].PagePath = 'pages/allProduct/allProduct'
        } else if (record.data.id == 3) {
          // 个人中心
          this.values.list[this.currrentIndex].PagePath = 'pages/personalCenter/mine/mine'
        } else if (record.data.id == 4) {
          // 购物车
          this.values.list[this.currrentIndex].PagePath = 'pages/shoppingCart/shoppingCart'
        } else if (record.data.id == 5) {
          // 积分中心
          this.values.list[this.currrentIndex].PagePath = 'pages/pointsCenter/pointsCenter'
        } else if (record.data.id == 6) {
          // 每日签到
          this.values.list[this.currrentIndex].PagePath = 'pages/userSign/userSign'
        } else if (record.data.id == 7) {
          // 客服
          this.values.list[this.currrentIndex].PagePath = 'pages/customerService/customerService'
        } else if (record.data.id == 8) {
          // 订单列表
          this.values.list[this.currrentIndex].PagePath = 'pages/order/myOrder/myOrder'
        } else if (record.data.id == 9) {
          // 分销礼包
          this.values.list[this.currrentIndex].PagePath = 'pages/invitation/invitationCard/invitationCard'
        }else if (record.data.id == 10) {
          // 成为会员
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/openSuccess/openSuccess'
        }else if (record.data.id == 11) {
          // 助力直播列表
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/live/live'
        }else if (record.data.id == 12) {
          // 扫码购
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/scan/scan'
        }else if (record.data.id == 13) {
          // 充值中心
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
        }else if (record.data.id == 14) {
          // 群分享
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/groupShare/groupShare'
        }else if (record.data.id == 15) {
          // 全部信息页
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/information/information'
        }else if (record.data.id == 16) {
          // 短视频带货
          this.values.list[this.currrentIndex].PagePath = 'pageC/pages/shortVideos/record'
        }else if (record.data.id == 17) {
          // 积分码
          this.values.list[this.currrentIndex].PagePath = 'pageA/pages/receive-point/receive-point'
        }else if (record.data.id == 18) {
          // 舌诊
            this.values.list[this.currrentIndex].PagePath = 'pageC/pages/tongueDiagnosis/home'
        }else if (record.data.id == 19) {
          // 企店即创主页
          this.values.list[this.currrentIndex].PagePath = 'pageC/pages/AIGC/home'
        }
      } else if (record.type == "product") {
        this.values.list[this.currrentIndex].PageName = record.data.Name
        this.values.list[this.currrentIndex].MenuType = 3
        this.values.list[this.currrentIndex].PagePath = 'pages/detail/detail?id=' + record.data.Id
      } else if (record.type == "custom") {
        this.values.list[this.currrentIndex].PageName = record.data.PageName
        this.values.list[this.currrentIndex].MenuType = 4
        this.values.list[this.currrentIndex].PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
      }else if (record.type == 'truntable'){
        this.values.list[this.currrentIndex].PageName = record.data.Name
        this.values.list[this.currrentIndex].MenuType = 6
        this.values.list[this.currrentIndex].PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
      } else if (record.type == 'live'){
        this.values.list[this.currrentIndex].PageName = record.data.RoomName
        this.values.list[this.currrentIndex].MenuType = 5
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
      }else if (record.type == 'coupon'){
        this.values.list[this.currrentIndex].PageName = record.data.CouponName
        this.values.list[this.currrentIndex].MenuType = 7
        this.values.list[this.currrentIndex].PagePath = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
      }else if (record.type == 'help') {
        this.values.list[this.currrentIndex].PageName = record.data.Name
        this.values.list[this.currrentIndex].MenuType = 8
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
      }else if (record.type == 'group') {
        this.values.list[this.currrentIndex].PageName = record.data.ProductName
        this.values.list[this.currrentIndex].MenuType = 9
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
      }else if (record.type == 'questionnaire') {
        this.values.list[this.currrentIndex].PageName = record.data.Title
        this.values.list[this.currrentIndex].MenuType = 10
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = 'pageA/pages/questionnaire/questionnaire?&referer=5&id=' + record.data.Id;
      }else if (record.type == 'taketest') {
        this.values.list[this.currrentIndex].PageName = record.data.Name
        this.values.list[this.currrentIndex].MenuType = 11
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = `pageC/pages/goodnessTest/home?id=` + record.data.Id
      }else if (record.type == 'contentgroup') {
        this.values.list[this.currrentIndex].PageName = record.data.GroupName
        this.values.list[this.currrentIndex].MenuType = 12
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = `pageA/pages/information/informationGroup?id=${record.data.Id}`
      }else if (record.type == 'singlecontent') {
        this.values.list[this.currrentIndex].PageName = record.data.Title
        this.values.list[this.currrentIndex].MenuType = 13
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = `pageA/pages/information/detail?id=${record.data.Id}`
      }else if (record.type == 'helpPro') {
        this.values.list[this.currrentIndex].PageName = record.data.ProductName
        this.values.list[this.currrentIndex].MenuType = 14
        this.values.list[this.currrentIndex].OtherData = record
        this.values.list[this.currrentIndex].PagePath = `pageC/pages/helpPro/helpPro?id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
      }
      
      this.values.list[this.currrentIndex].CustomerServiceReplyContentId = null
      this.values.list[this.currrentIndex].CommunityGroupCodeTitle = ''
    },
  }
}
</script>

<style lang="less" scoped>

  .flex{
    display: flex;
  }
  .flex-align-center{
    align-items: center;
  }
  .flex-column{
    flex-direction: column;
  }
  .flex-justify-between{
    justify-content: space-between;
  }
  .header-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .grayfont{
    color: #999999;
    line-height: 1.5;
    font-size: 14px;
  }
  .imglist{
    .demobox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .graybox{
        flex:1;
        background: #F5F5F5;
        padding:10px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px;
        // background: red;
        img{
          width: 70px;
          height: 70px;
          margin-right: 10px;
          flex-shrink: 0;
        }
        .textbox{
          width: 0;
          flex:1;
          .link-btn{
            cursor: pointer;
            color: #409eff;
            .link-text{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .uploadbox{
      ::v-deep .el-upload{
        width:100%;
      }
    }
    .pushbox{
      flex:1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 13px 0px;
      border:1px dashed #DDDDDDFF;
      width: 100%;
      .top{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // border: 1px solid black;
        color: #606266;
        line-height: 1.5;
        font-size: 14px;
      }
    }
  }
</style>